import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bordered-images media-posts"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_media_post_content = _resolveComponent("media-post-content")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_breadcrumbs, { elements: _ctx.generatedBreadcrumbs }, null, 8, ["elements"]),
    _createVNode("h1", null, "Media – " + _toDisplayString(this.$route.params.tag) + " – Page " + _toDisplayString(this.$route.params.page || '1'), 1),
    _createVNode(_component_pagination, {
      page: this.$route.params.page,
      lastPage: Math.ceil(_ctx.total / 10),
      path: `/media/tags/${this.$route.params.tag}`
    }, null, 8, ["page", "lastPage", "path"]),
    (_ctx.loaded === true)
      ? (_openBlock(), _createBlock("ul", _hoisted_1, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
            return (_openBlock(), _createBlock("li", {
              key: post.id
            }, [
              _createVNode(_component_media_post_content, { post: post }, null, 8, ["post"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_pagination, {
      page: this.$route.params.page,
      lastPage: Math.ceil(_ctx.total / 10),
      path: `/media/tags/${this.$route.params.tag}`
    }, null, 8, ["page", "lastPage", "path"])
  ], 64))
}