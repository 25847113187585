
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'pagination',
        props: {
            page: {
                default: '1',
                type: String,
            },
            lastPage: {
                type: Number,
                required: true,
            },
            path: {
                type: String,
                required: true,
            },
        },
        computed: {
            pageNumber(): number {
                return this.page
                    ? parseInt(this.page)
                    : 1;
            },
        }
    });
